import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      style={{
        padding: 120,
        flex: 1,
        textAlign: 'center',
        height: '100%',
      }}
    >
      <h1>Keep spraying!</h1>
      <p>Looks like something went wrong with this link.</p>
      <p>
        Head back to{' '}
        <a href="\" style={{ textDecoration: 'underline' }}>
          our homepage
        </a>
      </p>
      <img src="/images/notfound.png" alt="" style={{ maxHeight: 450 }} />
    </div>
  </Layout>
);

export default NotFoundPage;
